const embedProductSelector = {
  scriptId: "oneConfigProductSelector",
  containerId: "embedProductSelector",
  bucketURL: "BUCKET_URL",
  translationUrl: "TRANSLATION_URL",
  commonBucketUrl: "COMMON_BUCKET_URL",
  _patchProcess: function (init) {
    return init;
  },
  _validateInit: function (init) {
    const requiredFields = ["languageCode", "pricingGeo", "incoTerms"];
    const errors = [];
    for (const field of requiredFields) {
      if (typeof init[field] !== "string") {
        errors.push(`${field} is a required field`);
      }
    }
    if (errors.length > 0) {
      throw new Error(errors.join("\n"));
    }
    return init;
  },
  _warning: function () {
    const loaderDiv = document.createElement("div");
    loaderDiv.id = "configLoader";
    const loaderUrl = this.bucketURL + "loading.gif";
    loaderDiv.setAttribute(
      "style",
      `background: #e8e8e8 url(${loaderUrl}) no-repeat center content-box; height:100vh;`
    );
    document.getElementById("embedProductSelector")?.appendChild(loaderDiv);
  },
  _applyStyles: function (init) {
    const defaultColours = {
      primary: "#0096d6",
      secondary: "#d7410b",
      primaryHover: "#075782",
      primaryLight: "#e0f2fe",
      primaryLoader: "#22c55e",
      info: "#0171AD",
      success: "#4E9A51",
      warning: "#ffa500",
      error: "#d7410b"
    };
    for (const key in defaultColours) {
      const value =
        init.colourOverrides && init.colourOverrides[key]
          ? init.colourOverrides[key]
          : defaultColours[key];
      document.documentElement.style.setProperty(`--one-config-${key}`, value);
    }
    return init;
  },
  errorHandling: async function (request) {
    const response = await request.call(this);
    const resString = await response.text();
    const responseJson = JSON.parse(resString);
    if (!document.getElementById("configLoader")) {
      this._warning();
    }
    if (responseJson.errors) {
      throw new Error(responseJson.errors.map(error => error));
    }
    return responseJson;
  },
  getUiSpec: function (categoryName) {
    return fetch(`https://common.hpcloud.hp.com/${categoryName}-stepper.json`);
  },
  getCatalogLookUp: function (payload) {
    return fetch(this.gateway, {
      method: "POST",
      body: JSON.stringify({
        query:
          "query catalogLookup($filter:CatalogLookupRequest){catalogLookup(filter:$filter)}",
        variables: payload
      }),
      headers: {
        authorization: `Bearer ${this.token}`,
        "content-type": "application/json"
      }
    });
  },
  getSearchSavedConfigs: function (payload) {
    return fetch(this.gateway, {
      method: "POST",
      body: JSON.stringify({
        query:
          "query searchSavedConfig($searchConfigRequest:SearchConfigRequest!){searchSavedConfig(searchConfigRequest:$searchConfigRequest)}",
        variables: payload
      }),
      headers: {
        authorization: `Bearer ${this.token}`,
        "content-type": "application/json"
      }
    });
  },
  updateConfigurationStatus: function (input) {
    return fetch(this.gateway, {
      method: "POST",
      body: JSON.stringify({
        query:
          "mutation updateConfigurationStatus($input:ConfigStatusReq!){updateConfigurationStatus(input:$input){issuccess message}}",
        variables: input
      }),
      headers: {
        authorization: `Bearer ${this.token}`,
        "content-type": "application/json"
      }
    });
  },
  getRSKUMaterialNumber: function (payload) {
    return fetch(this.gateway, {
      method: "POST",
      body: JSON.stringify({
        query: "GET_RSKU_MATERIAL_NUMBER_QUERY",
        variables: payload
      }),
      headers: {
        authorization: `Bearer ${this.token}`,
        "content-type": "application/json"
      }
    });
  },
  getConfigBom: function (payload) {
    return fetch(this.gateway, {
      method: "POST",
      body: JSON.stringify({
        query: `GET_CONFIG_BOM_QUERY`,
        variables: payload
      }),
      headers: {
        authorization: `Bearer ${this.token}`,
        "content-type": "application/json"
      }
    });
  },
  getRSKUComponentList: function (payload) {
    return fetch(this.gateway, {
      method: "POST",
      body: JSON.stringify({
        query:
          "query getRSKUComponentList($skuNumbers: [String!]!) { getRSKUComponentList(skuNumbers: $skuNumbers) { errorMessage bomList { rskuMaterialNumber kmatId bomDetail { productNumber}}}}",
        variables: payload
      }),
      headers: {
        authorization: `Bearer ${this.token}`,
        "content-type": "application/json"
      }
    });
  },
  getPreApprovedConfigs: function (input) {
    return fetch(this.gateway, {
      method: "POST",
      body: JSON.stringify({
        query:
          "query preApprovedConfigs($filter: PreApprovedConfigsRequest!) { preApprovedConfigs(filter: $filter) }",
        variables: input
      }),
      headers: {
        authorization: `Bearer ${this.token}`,
        "content-type": "application/json"
      }
    });
  },
  getProductSelectorData: function (payload) {
    return fetch(this.gateway, {
      method: "POST",
      body: JSON.stringify({
        query: "PRODUCT_SELECTION_QUERY",
        variables: payload
      }),
      headers: {
        authorization: `Bearer ${this.token}`,
        "content-type": "application/json"
      },
      cache: "no-cache"
    });
  },
  getProductSelectionData: function (payload) {
    return fetch(this.gateway, {
      method: "POST",
      body: JSON.stringify({
        query:
          "query ($productSelectionFilter: ProductSelectionRequest){productSelection(filter: $productSelectionFilter)}",
        variables: payload
      }),
      headers: {
        authorization: `Bearer ${this.token}`,
        "content-type": "application/json"
      },
      cache: "no-cache"
    });
  },
  getProductAccessType: function (payload) {
    return fetch(this.gateway, {
      method: "POST",
      body: JSON.stringify({
        query:
          "query getProductAccessType($filter:productAccessTypeRequest){getProductAccessType(filter:$filter){productAccessType polyAccessType}}",
        variables: { filter: payload }
      }),
      headers: {
        authorization: `Bearer ${this.token}`,
        "content-type": "application/json"
      },
      cache: "no-cache"
    });
  },
  getRKSUAccess: function (payload) {
    return fetch(this.gateway, {
      method: "POST",
      body: JSON.stringify({
        query: "GET_RSKU_ACCESS_QUERY",
        variables: payload
      }),
      headers: {
        authorization: `Bearer ${this.token}`,
        "content-type": "application/json"
      },
      cache: "no-cache"
    });
  },
  getHPBasePrice: function (payload) {
    return fetch(this.gateway, {
      method: "POST",
      body: JSON.stringify({
        query:
          "query ($configDetails: HPBasePriceRequest!) {HPBasePrice(configDetails: $configDetails) {allConfigsItems{configID items{netPrice listPrice quantity}}}}",
        variables: payload
      }),
      headers: {
        authorization: `Bearer ${this.token}`,
        "content-type": "application/json"
      }
    });
  },
  getSearchProductCategories: function (input) {
    return fetch(this.gateway, {
      method: "POST",
      body: JSON.stringify({
        query:
          "query searchProductCategories($countryCode: String!, $proCategory: String!){searchProductCategories(countryCode: $countryCode proCategory: $proCategory)}",
        variables: input
      }),
      headers: {
        authorization: `Bearer ${this.token}`,
        "content-type": "application/json"
      }
    });
  },
  uploadConfigApi: function (payload) {
    return fetch(this.gateway, {
      method: "POST",
      body: JSON.stringify({
        query: `UPLOAD_CONFIG_QUERY`,
        variables: payload
      }),
      headers: {
        authorization: `Bearer ${this.token}`,
        "content-type": "application/json"
      },
      cache: "no-cache"
    });
  },
  createConfiguration: function (payload) {
    return fetch(this.gateway, {
      method: "POST",
      body: JSON.stringify({
        query:
          "mutation createConfiguration($input: NewSaveConfiguration!) { createConfiguration(input: $input) }",
        variables: payload
      }),
      headers: {
        authorization: `Bearer ${this.token}`,
        "content-type": "application/json"
      },
      cache: "no-cache"
    });
  },
  uploadConfigRestoration: function (payload) {
    return fetch(this.gateway, {
      method: "POST",
      body: JSON.stringify({
        query: `UPLOAD_CONFIG_RESTORATION_QUERY`,
        variables: payload
      }),
      headers: {
        authorization: `Bearer ${this.token}`,
        "content-type": "application/json"
      },
      cache: "no-cache"
    });
  },
  getLanguageCode: function () {
    const languageTable = {
      cs_CZ: "cs_CZ",
      de_DE: "de",
      el_GR: "el_GR",
      en_US: "en",
      es_ES: "es",
      fr_FR: "fr",
      hu_HU: "hu_HU",
      in_ID: "in_ID",
      it_IT: "it",
      ko_KR: "ko_KR",
      nl_NL: "nl_NL",
      pl_PL: "pl_PL",
      pt_BR: "pt_BR",
      ru_RU: "ru_RU",
      sk_SK: "sk_SK",
      th_TH: "th_TH",
      tr_TR: "tr_TR",
      vi_VN: "vi_VN",
      zh_CN: "zh_CN",
      zh_TW: "zh_TW",
      da_DK: "da_DK",
      no_NO: "no_NO",
      fi_FI: "fi_FI",
      et_EE: "et_EE",
      lv_LV: "lv_LV",
      hr_HR: "hr_HR",
      sl_SL: "sl_SL",
      bg_BG: "bg_BG",
      ro_RO: "ro_RO"
    };
    return languageTable[this.languageCode] || "en";
  },
  getTranslation: function (fileName) {
    return fetch(`${this.translationUrl}${fileName}.json`);
  },
  getNotification: function () {
    return fetch(`${this.commonBucketUrl}/notifications.json?${Date.now()}`);
  },
  launch: async function (initialData) {
    try {
      this._applyStyles(this._patchProcess(this._validateInit(initialData)));
    } catch (e) {
      return Promise.reject(e);
    }
    const allowedAttributes = [
      "token",
      "gateway",
      "currencyCode",
      "languageCode",
      "region",
      "country",
      "businessModel",
      "userEmail",
      "userType",
      "proCategory",
      "companyId",
      "openConfigCb",
      "countryCb",
      "pricingGeo",
      "incoTerms",
      "mDCPOrgID",
      "resellerID",
      "purchaseAgreementNumber",
      "userId",
      "transactionID",
      "locationID",
      "prtnrPortalFlag",
      "isStandAloneAccess",
      "isDisplayProdCountry",
      "showLandingPage",
      "showSearchConfig",
      "showStartingPointPrice",
      "fontSupplied",
      "showListPriceText",
      "showOCFeatures",
      "showUploadConfig",
      "clientOrigin",
      "addloaderCb",
      "resetUploadConfigCb",
      "addToQuoteCb",
      "hasPrintAccess",
      "isOriginOCA"
    ];

    const defaults = {
      prtnrPortalFlag: false,
      isStandAloneAccess: false,
      isDisplayProdCountry: false,
      showLandingPage: false,
      showSearchConfig: false,
      showStartingPointPrice: false,
      showListPriceText: false,
      showOCFeatures: false,
      showUploadConfig: false,
      clientOrigin: "",
      hasPrintAccess: false,
      isOriginOCA: false
    };

    allowedAttributes.forEach(key => {
      embedProductSelector[key] =
        initialData[key] !== undefined ? initialData[key] : defaults[key];
    });

    if (!document.getElementById(this.scriptId)) {
      const loadScript = async () => {
        return new Promise((resolve, reject) => {
          const script = document.createElement("script");
          script.id = this.scriptId;
          script.src = this.bucketURL + "bundle.js";
          script.onload = () => resolve();
          script.onerror = () => reject(new Error(`Failed to load script`));
          document.body.appendChild(script);
        });
      };
      try {
        await loadScript();
      } catch (e) {
        console.log(e);
      }
    }
    if (!initialData.fontSupplied) {
      const style = document.createElement("style");
      style.innerHTML = `@import url(${this.bucketURL}font-family.css)`;
      document.head.appendChild(style);
    }
    try {
      await (this.showUploadConfig
        ? this._UploadBootstrap()
        : this._bootstrap());
      document.getElementById("configLoader")?.remove();
      return Promise.resolve("ProductSelector started");
    } catch (e) {
      return Promise.reject(e);
    }
  },
  _bootstrap: async function () {
    this._observe();
    const languageCode = this.getLanguageCode();
    const productAccessTypeFilter = {
      companyId: this.companyId,
      userType: this.userType,
      country: this.country
    };

    const [translations, productAccessType] = await Promise.all([
      this.errorHandling(this.getTranslation.bind(this, languageCode)),
      this.errorHandling(
        this.getProductAccessType.bind(this, productAccessTypeFilter)
      )
    ]);

    const productAccessTypeValue =
      productAccessType &&
      productAccessType.data.getProductAccessType.productAccessType;

    const proCategory =
      productAccessTypeValue && !["all", ""].includes(productAccessTypeValue)
        ? productAccessTypeValue
        : this.proCategory;

    const payload = {
      countryCode: this.country,
      proCategory: proCategory,
      productSelectionFilter: {
        country: this.country,
        region: this.region,
        routeToMarket: this.businessModel
      },
      showRSKU: this.showOCFeatures,
      userEmail: this.userEmail || "",
      regionCode: this.region
    };

    const [productSelectorResponse, notifications] = await Promise.all([
      this.errorHandling(this.getProductSelectorData.bind(this, payload)),
      this.errorHandling(this.getNotification.bind(this))
    ]);
    const productSelectorData = {
      accessType: productAccessType.data.getProductAccessType,
      productSelection: productSelectorResponse.data.productSelection,
      productCategories:
        productSelectorResponse.data.searchProductCategories.productCategories,
      rskuUserAccess: this.showOCFeatures
        ? productSelectorResponse.data.getRskuUserAccess
        : {},
      languageCode,
      translations,
      notifications
    };
    this._launchProductSelector(productSelectorData);
  },
  _launchProductSelector: function (productSelectorData) {
    document.getElementById("configLoader")?.remove();
    window.productSelectorApp({
      ...productSelectorData,
      currencyCode: this.currencyCode,
      region: this.region,
      country: this.country,
      businessModel: this.businessModel,
      userEmail: this.userEmail,
      userType: this.userType,
      proCategory: this.proCategory,
      companyId: this.companyId,
      pricingGeo: this.pricingGeo,
      incoTerms: this.incoTerms,
      mDCPOrgID: this.mDCPOrgID,
      resellerID: this.resellerID,
      purchaseAgreementNumber: this.purchaseAgreementNumber,
      userId: this.userId,
      transactionID: this.transactionID,
      locationID: this.locationID,
      hasPrintAccess: this.hasPrintAccess,
      isOriginOCA: this.isOriginOCA,
      prtnrPortalFlag: this.prtnrPortalFlag,
      isStandAloneAccess: this.isStandAloneAccess,
      isDisplayProdCountry: this.isDisplayProdCountry,
      showLandingPage: this.showLandingPage,
      showSearchConfig: this.showSearchConfig,
      showStartingPointPrice: this.showStartingPointPrice,
      showListPriceText: this.showListPriceText,
      showOCFeatures: this.showOCFeatures,
      clientOrigin: this.clientOrigin,
      showUploadConfig: this.showUploadConfig
    });
  },
  _UploadBootstrap: async function () {
    this._observe();
    const languageCode = this.getLanguageCode();
    const payload = {
      userEmail: this.userEmail || "",
      countryCode: this.country,
      regionCode: this.region
    };
    const [translations, rSKUResponse] = await Promise.all([
      this.errorHandling(this.getTranslation.bind(this, languageCode)),
      this.errorHandling(this.getRKSUAccess.bind(this, payload))
    ]);
    const productSelectorData = {
      accessType: "",
      productSelection: {},
      productCategories: [],
      rskuUserAccess: rSKUResponse.data.getRskuUserAccess,
      notifications: {},
      translations,
      languageCode
    };
    this._launchProductSelector(productSelectorData);
  },
  cleanUp: function () {
    window["product-selector"].appInstance.$destroy();
    window.removeEventListener("message", this.listener);
    const productSelector = document.querySelector("product-selector");
    if (productSelector) productSelector.remove();
  },
  listener: function (event) {
    if (event?.data?.type === "LAUNCH_CONFIGURATOR") {
      const { payload } = event.data;
      embedProductSelector.openConfig(payload);
    } else if (event?.data?.type === "COUNTRY_CHANGE") {
      embedProductSelector._countryUpdate(event.data.payload);
    }
  },
  _observe: function (init) {
    window.addEventListener("message", this.listener);
    return init;
  },
  _countryUpdate: function (payload) {
    if (this.countryCb) this.countryCb(payload);
  },
  openConfig: function (payload) {
    if (this.openConfigCb) this.openConfigCb(payload);
  }
};
window.embedProductSelector = embedProductSelector;